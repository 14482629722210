/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AddressDTO } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class AddressApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * @description Update the address of a company
     *
     * @tags address-api
     * @name UpdateAddress
     * @summary Update address
     * @request POST:/api/address/{id}
     * @secure
     */
    updateAddress = (id: number, data: AddressDTO, params: RequestParams = {}) =>
        this.request<AddressDTO, (Record<string, string> | string[]) | void | AddressDTO>({
            path: `/api/address/${id}`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
}
