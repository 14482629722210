/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { FefcoCodeDTO } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class FefcoCodeApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * @description Returns a specific FEFCO code by its code.
     *
     * @tags fefco-code-api
     * @name GetFefcoCodeByCode
     * @summary Retrieve a FEFCO code by code
     * @request GET:/api/fefco-codes/{code}
     * @secure
     */
    getFefcoCodeByCode = (code: string, params: RequestParams = {}) =>
        this.request<FefcoCodeDTO, (Record<string, string> | string[]) | void>({
            path: `/api/fefco-codes/${code}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params
        });
    /**
     * @description Returns a list of all FEFCO codes available in the database.
     *
     * @tags fefco-code-api
     * @name GetFefcoCodes
     * @summary Retrieve all FEFCO codes
     * @request GET:/api/fefco-codes
     * @secure
     */
    getFefcoCodes = (params: RequestParams = {}) =>
        this.request<FefcoCodeDTO[], (Record<string, string> | string[]) | void>({
            path: `/api/fefco-codes`,
            method: "GET",
            secure: true,
            format: "json",
            ...params
        });
}
