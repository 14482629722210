/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiTokenDTO, UserDTO, UserDisplayDTO } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class UserApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags user-api
     * @name CreateApiToken
     * @summary This API creates an API token for a user. An old token gets invalidated.
     * @request POST:/api/users/create-api-token
     * @secure
     */
    createApiToken = (params: RequestParams = {}) =>
        this.request<ApiTokenDTO, (Record<string, string> | string[]) | void>({
            path: `/api/users/create-api-token`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags user-api
     * @name GetActiveCompanyUsers
     * @summary get active users of current company
     * @request GET:/api/users/active-company-users
     * @secure
     */
    getActiveCompanyUsers = (params: RequestParams = {}) =>
        this.request<UserDisplayDTO[], (Record<string, string> | string[]) | void | UserDisplayDTO[]>({
            path: `/api/users/active-company-users`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * @description You can only read user of your company
     *
     * @tags user-api
     * @name GetUser
     * @summary Load user
     * @request GET:/api/users/{id}
     * @secure
     */
    getUser = (id: number, params: RequestParams = {}) =>
        this.request<UserDTO, (Record<string, string> | string[]) | void | UserDTO>({
            path: `/api/users/${id}`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * @description Only the user it self can save it's data
     *
     * @tags user-api
     * @name SaveUser
     * @summary save user
     * @request PUT:/api/users/{id}
     * @secure
     */
    saveUser = (id: number, data: UserDTO, params: RequestParams = {}) =>
        this.request<UserDTO, (Record<string, string> | string[]) | void | UserDTO>({
            path: `/api/users/${id}`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
}
