// EmailHistoryDialog.tsx

import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { MailStatus, RequestMailDTO } from "@/api/data-contracts";
import { DataTable } from "@/lib/components/DataTable";
import dayjs from "dayjs";
import { companyApi, requestApi } from "@/boxhub-api";
import { DateFormatter } from "@/lib/components/DateFormate";
import { useSnackbarEx } from "@/lib/snackbarex";
import { useTranslation } from "react-i18next";
import { EmailHistoryI18N, EMailStatusI18N, GeneralI18N } from "@/translations";
import { DialogEx } from "@/lib/components/DialogEx";
import Box from "@mui/material/Box";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";

/**
 * Props for the EmailHistoryDialog component.
 *
 * @property {boolean} open - Whether the dialog is open.
 * @property {number} requestId - The ID of the request to fetch email history for.
 * @property {() => void} onClose - The function to call when the dialog is closed.
 * @property {boolean} [enableSendStatus=true] - If true, enables the send status column.
 */
interface EmailHistoryDialogProps {
    open: boolean;
    requestId?: number;
    onClose: () => void;
    enableSendStatus?: boolean;
}

/**
 * EmailHistoryDialog component.
 *
 * @param {EmailHistoryDialogProps} props - The props for the component.
 * @returns {React.ReactElement} The rendered EmailHistoryDialog component.
 */
const EmailHistoryDialog: React.FC<EmailHistoryDialogProps> = ({
    open,
    requestId,
    onClose,
    enableSendStatus = true
}: EmailHistoryDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const [emails, setEmails] = useState<RequestMailDTO[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const dateFormat = DateFormatter("date_hm");
    const { showMessage } = useSnackbarEx();

    // Table columns
    const columns = [
        {
            header: t(EmailHistoryI18N.createdAt),
            accessorKey: "createdAt",
            cell: (info: any) => dayjs(info.getValue()).format(dateFormat)
        },
        {
            header: t(EmailHistoryI18N.sentAt),
            accessorFn: (row: RequestMailDTO) => row.mail?.sent,
            cell: (info: any) =>
                info.getValue() ? dayjs(info.getValue()).format(dateFormat) : t(EmailHistoryI18N.notSent)
        },
        ...(enableSendStatus
            ? [
                  {
                      header: t(EmailHistoryI18N.sendStatus),
                      accessorFn: (row: RequestMailDTO) => row.mail?.status,
                      cell: (info: any) => {
                          const status = info.getValue();
                          if (status === MailStatus.SENT) {
                              return t(EMailStatusI18N[MailStatus.SENT]);
                          }
                          if (status === MailStatus.ERROR) {
                              return t(EMailStatusI18N[MailStatus.ERROR]);
                          }
                          return t(EMailStatusI18N[MailStatus.NOT_SENT]);
                      }
                  }
              ]
            : []),
        {
            header: t(EmailHistoryI18N.sender),
            accessorKey: "sender",
            cell: () => t(EmailHistoryI18N.boxhubSystem)
        },
        {
            header: t(EmailHistoryI18N.recipient),
            accessorFn: (row: RequestMailDTO) => row.receiver,
            cell: (info: any) => {
                const company = info.getValue() || [];
                return company.name;
            }
        },
        {
            header: t(EmailHistoryI18N.subject),
            accessorFn: (row: RequestMailDTO) => row.mail?.subject,
            cell: (info: any) => info.getValue() || ""
        }
    ];

    // Fetch emails when dialog opens
    useEffect(() => {
        if (open) {
            setLoading(true);
            const fetch =
                (requestId && requestApi.getRequestEMailHistory(requestId)) || companyApi.getCompanyEMailHistory();
            fetch
                .then((response) => {
                    setEmails(response.data.mails ?? []);
                })
                .catch((error) => {
                    showMessage({ summary: t(GeneralI18N.unknownError), severity: "error", error });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [open, requestId, showMessage, t]);

    return (
        <DialogEx
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            title={t(EmailHistoryI18N.emailHistoryTitle)}
            buttons={
                <ButtonEx label={t(GeneralI18N.closeButton)} color="secondary" variant="outlined" onClick={onClose} />
            }
        >
            <Box
                style={{
                    height: "600px",
                    overflowY: "auto"
                }}
            >
                {loading ? (
                    <CircularProgress />
                ) : (
                    <DataTable
                        columns={columns}
                        data={emails as any}
                        noResultsValue={t(EmailHistoryI18N.noEmailsAvailable)}
                        expandable
                        renderRowSubComponent={(row) => (
                            <div style={{ padding: "16px" }}>
                                <strong>{t(EmailHistoryI18N.emailMessage)}:</strong>
                                <div
                                    style={{ marginTop: "10px" }}
                                    dangerouslySetInnerHTML={{ __html: row.original.mail?.message || "" }}
                                />
                            </div>
                        )}
                        maxHeight={550}
                    />
                )}
            </Box>
        </DialogEx>
    );
};

export default EmailHistoryDialog;
