/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ErrorResponseDTO, OrderDocumentDTO, OrderDocumentType } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class OrderDataDocumentApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags order-data-document-api
     * @name DeleteOrderDocument
     * @summary Delete a document by ID
     * @request DELETE:/api/order-documents/{id}
     * @secure
     */
    deleteOrderDocument = (id: number, params: RequestParams = {}) =>
        this.request<object, (Record<string, string> | string[]) | void | ErrorResponseDTO>({
            path: `/api/order-documents/${id}`,
            method: "DELETE",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags order-data-document-api
     * @name DownloadOrderDocument
     * @summary Get an document by ID
     * @request GET:/api/order-documents/{id}/download/{filename}
     * @secure
     */
    downloadOrderDocument = (id: number, filename: string, params: RequestParams = {}) =>
        this.request<string, (Record<string, string> | string[]) | void | string>({
            path: `/api/order-documents/${id}/download/${filename}`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags order-data-document-api
     * @name UploadOrderDocument
     * @summary Upload a PDF document
     * @request POST:/api/order-documents/upload
     * @secure
     */
    uploadOrderDocument = (
        query: {
            /** @format int64 */
            orderDocumentsId: number;
            type: OrderDocumentType;
        },
        data: {
            files: File[];
        },
        params: RequestParams = {}
    ) =>
        this.request<OrderDocumentDTO[], (Record<string, string> | string[]) | void>({
            path: `/api/order-documents/upload`,
            method: "POST",
            query: query,
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params
        });
}
