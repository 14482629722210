// AuthContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { authenticationApi } from "@/boxhub-api";
import PageLoadingSpinner from "@/lib/components/PageLoadingSpinner";
import { CompanyDisplayDTO, SessionDTO, UserDisplayDTO, UserRole } from "@/api/data-contracts";

import packageJson from "../../package.json";
import { setGlobalDateDisplacement } from "@/lib/date-utils";

interface AuthContextType {
    authenticated: boolean;
    user?: UserDisplayDTO;
    role?: UserRole;
    admin?: boolean;
    company?: CompanyDisplayDTO;
    serverVersion?: string;
    clientVersion?: string;
    environment?: string;
    timedisplacement?: number;
    setTimeDisplacement: (timedisplacement?: number) => void;
    loading: boolean; // Add loading to the context type
    checkAuth: () => Promise<void>;
    login: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [session, setSession] = useState<SessionDTO>();
    const [loading, setLoading] = useState(true);

    const checkAuth = async () => {
        setLoading(true);
        try {
            const response = await authenticationApi.checkAuth();
            setSession(response.data);
            setGlobalDateDisplacement(response.data.displacementDays);
        } catch (error) {
            setSession(undefined);
        } finally {
            setLoading(false);
        }
    };

    const login = async (email: string, password: string) => {
        try {
            const response = await authenticationApi.signIn({ email, password });
            setSession(response.data);
            setGlobalDateDisplacement(response.data.displacementDays);
        } catch (error) {
            setSession(undefined);
            throw error;
        }
    };

    const logout = async () => {
        await authenticationApi.signOut();
        setSession(undefined);
    };

    useEffect(() => {
        checkAuth();
    }, []);

    if (loading) {
        return <PageLoadingSpinner />;
    }

    return (
        <AuthContext.Provider
            value={{
                authenticated: session?.user != null,
                user: session?.user,
                company: session?.company,
                role: session?.role,
                admin: session?.admin,
                serverVersion: session?.serverVersion,
                environment: session?.environment,
                clientVersion: packageJson.version,
                timedisplacement: session?.displacementDays,
                setTimeDisplacement: (timedisplacement) =>
                    setSession({
                        ...session,
                        displacementDays: timedisplacement
                    }),
                loading,
                checkAuth,
                login,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
}
