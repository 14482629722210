import * as React from "react";
import { Grid2, Grid2 as Grid, Pagination, useTheme } from "@mui/material";
import { QuoteDTO, QuoteStatus, RequestStatus } from "@/api/data-contracts";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import { CardEx } from "@/lib/components/CardEx";
import i18n from "@/i18n";
import DateInputField from "@/lib/components/inputfields/date-input-field";
import { useTranslation } from "react-i18next";
import { GeneralI18N, RequestKeyWordsI18N } from "@/translations";
import { ChangeHandler } from "@/lib/modelstate";
import { Constants } from "@/api/constants";
import AutocompleteInputField from "@/lib/components/inputfields/auto-complete-input-field";
import { useEmployees } from "@/datacaches";
import { convertToThreeDigitString, ErrorsShape } from "@/lib/utils";
import { OrderDataType } from "@/model/orderData";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";
import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { QuoteStatusBadgeField } from "@/common/QuoteStatusBadge";
import { brown, orange, red } from "@mui/material/colors";
import { Badge } from "@/lib/components/Badge";

export type QuoteCardProps = {
    model?: QuoteDTO;
    errors?: ErrorsShape<QuoteDTO>;
    requestStatus?: RequestStatus;
    onChange: ChangeHandler;
    readonly?: boolean;
    mode?: OrderDataType;
    quotes?: QuoteDTO[];
    quoteIdx?: number;
    setQuoteIdx?: (idx: number) => void;
    handleConfirmQuote?: () => void;
    handleDismissQuote?: () => void;
};

export const QuoteCard = ({
    model,
    errors,
    requestStatus,
    readonly,
    onChange,
    mode,
    quotes,
    quoteIdx,
    setQuoteIdx,
    handleConfirmQuote,
    handleDismissQuote
}: QuoteCardProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const locale = i18n.language;
    const [employees] = useEmployees();
    const titleText = mode === "accept" ? `Angebot von ${model?.company?.name}` : "Ihr Angebot";

    const title = (
        <Grid2 container direction="row">
            <Grid2 flexGrow={1}>
                <span>{titleText}</span>
                &nbsp;
                {mode === "accept" ? null : (
                    <Badge label="Abgelaufen" backgroundColor={red[theme.palette.mode === "dark" ? 800 : 200]} />
                )}
            </Grid2>
            {setQuoteIdx && quotes && quotes?.length > 0 ? (
                <Grid2>
                    <Pagination
                        count={quotes.length}
                        page={(quoteIdx ?? 0) + 1}
                        variant="outlined"
                        shape="rounded"
                        onChange={(e, page) => setQuoteIdx(page - 1)}
                    />
                </Grid2>
            ) : null}
        </Grid2>
    );

    const acceptRow =
        mode === "accept" ? (
            requestStatus === RequestStatus.TO_BE_CONFIRMED && model?.status === QuoteStatus.SUBMITTED ? (
                <Grid container size={{ sm: 12 }}>
                    <Grid size={{ sm: 6 }}>
                        <ButtonEx
                            startIcon={<ThumbUp />}
                            variant="contained"
                            color="success"
                            label="Angebot annehmen"
                            onClick={handleConfirmQuote}
                            fullWidth
                            disabled={
                                requestStatus !== RequestStatus.TO_BE_CONFIRMED ||
                                model?.status !== QuoteStatus.SUBMITTED ||
                                model?.orderData?.quantityOptionSelected == null
                            }
                        />
                    </Grid>
                    <Grid size={{ sm: 6 }}>
                        <ButtonEx
                            startIcon={<ThumbDown />}
                            variant="contained"
                            color="error"
                            label="Angebot ablehnen"
                            onClick={handleDismissQuote}
                            fullWidth
                            disabled={
                                requestStatus !== RequestStatus.TO_BE_CONFIRMED ||
                                model?.status !== QuoteStatus.SUBMITTED
                            }
                        />
                    </Grid>
                </Grid>
            ) : (
                <Grid size={{ sm: 12 }}>
                    <QuoteStatusBadgeField status={model?.status} />
                </Grid>
            )
        ) : null;

    return (
        <CardEx title={title} sx={{ backgroundColor: theme.palette.mode === "dark" ? brown[900] : orange[100] }}>
            <Grid container direction="row" spacing={2} size={{ sm: 12 }}>
                <Grid size={{ sm: 12 }} container direction="row" spacing={2}>
                    <Grid size={{ sm: 6 }}>
                        <StringInputField
                            name="customerReferenceNumber"
                            value={model?.customerReferenceNumber}
                            label={t(RequestKeyWordsI18N.customerQuoteNumber)}
                            error={errors?.customerReferenceNumber}
                            maxChars={Constants.CUSTOMER_REFERENCE_NUMBER_LENGTH_MAX}
                            activatePrefix
                            onChange={onChange}
                            prefixValue={
                                model?.company?.number != null
                                    ? `${convertToThreeDigitString(model?.company?.number)} -`
                                    : t(GeneralI18N.notGiven)
                            }
                            disabled={readonly}
                        />
                    </Grid>
                    <Grid size={{ sm: 6 }}>
                        <DateInputField
                            name="validUntil"
                            label={t(RequestKeyWordsI18N.quoteValidityLabel)}
                            value={model?.validUntil}
                            error={errors?.validUntil}
                            onChange={onChange}
                            disabled={readonly}
                        />
                    </Grid>
                </Grid>
                <Grid size={{ sm: 6 }}>
                    <DateInputField
                        name="orderData.deliveryDate"
                        label={t(RequestKeyWordsI18N.deliveryDateFieldLabel)}
                        value={model?.orderData?.deliveryDate}
                        error={errors?.orderData?.deliveryDate}
                        onChange={onChange}
                        disabled={readonly}
                    />
                </Grid>
                <Grid size={{ sm: 12 }}>
                    <StringInputField
                        name="additionalInformation"
                        label={t(RequestKeyWordsI18N.quoteInformation)}
                        value={model?.additionalInformation}
                        error={errors?.additionalInformation}
                        maxChars={Constants.ADDITIONAL_INFORMATION_LENGTH_MAX}
                        onChange={onChange}
                        multiline
                        disabled={readonly}
                    />
                </Grid>
                <Grid size={{ sm: 12 }}>
                    <StringInputField
                        name="orderData.paymentTerms"
                        label={t(RequestKeyWordsI18N.paymentTerms)}
                        value={model?.orderData?.paymentTerms}
                        onChange={onChange}
                        error={errors?.orderData?.paymentTerms}
                        multiline
                        maxChars={Constants.PAYMENT_TERMS_LENGTH_MAX}
                        disabled={readonly}
                    />
                </Grid>
                <Grid size={{ sm: 12 }}>
                    <AutocompleteInputField
                        name="contactPerson"
                        label={t(RequestKeyWordsI18N.contactPerson)}
                        onChange={onChange}
                        value={model?.contactPerson}
                        error={errors?.contactPerson}
                        options={employees}
                        getOptionLabel={(option) =>
                            (option && `${option.firstName} ${option.lastName} <${option.email}>`) ?? ""
                        }
                        disabled={readonly}
                    />
                </Grid>
                {mode === "accept" && acceptRow}
            </Grid>
        </CardEx>
    );
};
